import React, { Component, Suspense } from 'react'
import { HashRouter, Route, Routes } from 'react-router-dom'
import './scss/style.scss'
import ResetPassword from './views/pages/auth/resetPassword/sendResetPassword'
import SetPassword from './views/pages/auth/resetPassword/setPassword'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Cookies from 'js-cookie'
import LoginEmployee from './views/pages/auth/login/LoginEmployee'

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
const OTPVerificationForm = React.lazy(() => import('./views/pages/auth/register/Verify_Otp'))
const Login = React.lazy(() => import('./views/pages/auth/login/Login'))
const Register = React.lazy(() => import('./views/pages/auth/register/Register'))
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))

class App extends Component {
  render() {
    var token = this.props.token
    return (
      <HashRouter>
        <Suspense fallback={loading}>
          <Routes>
            <Route
              exact
              path="/register/enter-otp"
              name="Verify-Otp"
              element={<OTPVerificationForm></OTPVerificationForm>}
            ></Route>
            <Route exact path="/login/employee" name="Login Page" element={<LoginEmployee />} />
            <Route exact path="/login" name="Login Page" element={<Login />} />
            <Route exact path="/register" name="Register Page" element={<Register />} />
            <Route exact path="/404" name="Page 404" element={<Page404 />} />
            <Route exact path="/500" name="Page 500" element={<Page500 />} />
            <Route exact path="/reset/password" name="Reset Password" element={<ResetPassword />} />

            <Route
              exact
              path="/set/password/:token"
              name="Set Password"
              element={<SetPassword />}
            />
            <Route path="*" name="Home" element={token ? <DefaultLayout /> : <Login />} />
          </Routes>
        </Suspense>
      </HashRouter>
    )
  }
}

App.propTypes = {
  isLogin: PropTypes.any,
}

function mapStateToProps(state) {
  var token = Cookies.get('auth')
  var isLogin = state.isLogin
  if ((token !== null || token !== '') && token !== undefined && isLogin === undefined) {
    isLogin = true
  }

  return {
    token,
  }
}

// export default App

export default connect(mapStateToProps)(App)
