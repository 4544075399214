import { useNavigate } from 'react-router-dom'
import logo from '../../../../assets/images/foduu-logo.png'

import {
  CAlert,
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CFormLabel,
  CInputGroup,
  CRow,
} from '@coreui/react'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import AuthHelpers from 'src/helpers/AuthHelpers'
import * as Yup from 'yup'

const validationSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email').required('Email is required'),
  password: Yup.string().required('Password is required'),
})
const LoginEmployee = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const isNotLoggin = useSelector((state) => state.isNotLoggin)
  return (
    <div className="bg-theme-color min-vh-100 d-flex flex-row align-items-center">
      <CContainer>
        {isNotLoggin && <CAlert color="danger">{isNotLoggin}</CAlert>}

        <CRow className="justify-content-center">
          <CCol md={5}>
            <CCardGroup>
              <CCard className="p-4 login_card">
                <CCardBody>
                  <Formik
                    initialValues={{
                      email: '',
                      password: '',
                    }}
                    validationSchema={validationSchema}
                    onSubmit={(values, { setSubmitting }) => {
                      AuthHelpers.login(values, navigate, dispatch, 'employee').finally(() => {
                        setSubmitting(false)
                      })
                    }}
                  >
                    <Form className="admin-login-page">
                      <div className="text-center">
                        <img src={logo} alt="logo" className="w-50" />
                        <p className="text-medium-emphasis mt-3 mb-3">Sign In to Employyee account</p>
                      </div>
                      <CRow className="mb-2">
                        <CFormLabel htmlFor="exampleInputEmail1" className="label-text">
                          Email<span className="text-danger">*</span>
                        </CFormLabel>
                        <CInputGroup>
                          {/* <CInputGroupText className="bg-theme-color ">
                            <CIcon icon={cilUser} />
                          </CInputGroupText> */}
                          <Field
                            type="text"
                            name="email"
                            placeholder="Enter your email"
                            autoComplete="email"
                            className="form-control"
                          />
                        </CInputGroup>
                        <ErrorMessage
                          name="email"
                          component="div"
                          className="text-danger text-start"
                        />
                      </CRow>
                      <CRow className="mb-4">
                        <CFormLabel htmlFor="exampleInputEmail1" className="label-text">
                          Password<span className="text-danger">*</span>
                        </CFormLabel>
                        <CInputGroup>
                          {/* <CInputGroupText className="bg-theme-color border-none">
                            <CIcon icon={cilLockLocked} />
                          </CInputGroupText> */}
                          <Field
                            type="password"
                            name="password"
                            placeholder="Enter your password"
                            autoComplete="current-password"
                            className="form-control "
                          />
                        </CInputGroup>
                        <ErrorMessage
                          name="password"
                          component="div"
                          className="text-danger text-start"
                        />
                      </CRow>
                      <CRow>
                        <CCol xs={12} className="m-auto ">
                          <CButton className="px-4 sign-up" type="submit">
                            Login
                          </CButton>
                        </CCol>
                        {/* <CCol xs={6} className="text-right">
                          <CButton
                            color="link"
                            className="px-0"
                            onClick={() => {
                              navigate('/reset/password')
                            }}
                          >
                            Forgot password?
                          </CButton>
                        </CCol> */}
                      </CRow>
                    </Form>
                  </Formik>
                </CCardBody>
              </CCard>
              {/* <CCard className="text-white bg-primary py-5" style={{ width: '44%' }}>
                <CCardBody className="text-center">
                  <div>
                    <h2>Sign up</h2>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
                      tempor incididunt ut labore et dolore magna aliqua.
                    </p>
                    <Link to="/register">
                      <CButton color="primary" className="mt-3" active tabIndex={-1}>
                        Register Now!
                      </CButton>
                    </Link>
                  </div>
                </CCardBody>
              </CCard> */}
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  )
}

export default LoginEmployee
