import Cookies from 'js-cookie'
import BasicProvider from 'src/constants/BasicProvider'
import jwt_decode from 'jwt-decode'

class AuthHelpers {
  static async login(formdata, navigate, dispatch, type) {
    try {
      var response;
      if (type === 'admin') {
        response = await new BasicProvider('auth/signin', navigate, dispatch).postRequest(
          formdata,
        )
      } else {
        response = await new BasicProvider('auth/signin/employee', navigate, dispatch).postRequest(
          formdata,
        )
      }




      var authData = response.data.user
      const numericValue = parseInt(response.data.expireTime, 10)
      Cookies.set('auth', response.data.token, {
        expires: parseInt(numericValue),
        path: '',
        domain: process.env.REACT_APP_URL,
        sameSite: 'strict',
      })
      Cookies.set('type', type, {
        expires: parseInt(numericValue),
        path: '',
        domain: process.env.REACT_APP_URL,
        sameSite: 'strict',
      })
      dispatch({ type: 'set', isLogin: true })
      dispatch({ type: 'setUserData', ...authData })
      navigate('/dashboard')
    } catch (error) {
      console.error(error.data)
      if (error?.data === 'Error: Please enter valid email and password!') {
        // Cookies.remove('auth', { path: '', domain: process.env.REACT_APP_URL })
        dispatch({ type: 'set', isNotLoggin: error.data })
      }
    }
  }
  static async logout(token) {
    try {
      // console.log(id)
      Cookies.remove('auth', { path: '', domain: process.env.REACT_APP_URL })
      Cookies.remove('userId', { path: '', domain: process.env.REACT_APP_URL })
      await new BasicProvider('auth/signout').postRequest({ token: token })
    } catch (error) {
      console.error(error)
    }
  }
}
export default AuthHelpers
